import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = ({ author, title }) => (
  <div className="footer">
    <div className="container">
      {/* <hr className="border-primary" /> */}
      {/* <p>
        {title}
        <Link to="/profile/">
          <br />
          <strong>{author}</strong> on Profile
        </Link>
      </p> */}
      <div className="row">
        <p className="text-secondary mt-5 mr-auto ml-auto">
          Made with Gatsby, React, ZDog. Hosted on Netlify
        </p>
      </div>
    </div>
  </div>
)

export default Footer
