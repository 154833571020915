import React, { useRef, useState, useEffect } from 'react'

// import Zfont from 'zfont'
// import Zdog from 'zdog'

import {
  Illustration,
  Ellipse,
  Shape,
  RoundedRect,
  useRender,
} from 'react-zdog'

// Zfont.init(Zdog)

// // Create a Zdog illustration
// let illo = new Zdog.Illustration({
//   element: '.rotating-n',
// })

// // Set up a font to use
// let myFont = new Zdog.Font({
//   src: '/static/fonts/PressStart2P-Regular.ttf',
// })

// // Create a text object
// // This is just a Zdog.Shape object with a couple of extra parameters!
// new Zdog.Text({
//   addTo: illo,
//   font: myFont,
//   value: 'Hey, Zdog!',
//   fontSize: 64,
//   color: 'green',
// })

// // Animation loop
// function animate() {
//   illo.updateRenderGraph()
//   requestAnimationFrame(animate)
// }
// animate()
const TAU = Math.PI * 0.4

function exportRotatingN(props) {
  const colori = [
    'tomato',
    'red',
    'white',
    'violet',
    'orange',
    'brown',
    'chocolate',
    'blue',
    'deeppink',
    'fuchsia',
    'gold',
  ]

  let letChangedColorAmount = 0
  let _rotating = true
  const [randomColor, setRandomColor] = useState('white')
  const [changedColorAmount, setChangedColorAmount] = useState(0)
  const [up, setUp] = useState(true)
  const [rotating, setRotating] = useState(true)
  // only once
  useEffect(() => {
    let randomColorInterval = setInterval(() => {
      // console.log('setinterval up is: ', up)

      let coloriFiltered = colori.filter(colore => colore !== randomColor)

      setRandomColor(
        coloriFiltered[Math.floor(Math.random() * coloriFiltered.length)]
      )
      letChangedColorAmount += 1
      // console.log(letChangedColorAmount)
      setUp(previous => !previous)
      if (letChangedColorAmount > 10) {
        setRotating(false)
        _rotating = false
        // console.log('setRotating(false)', rotating, _rotating)
        // console.log('letChangedColorAmount', letChangedColorAmount)
        // clearInterval(randomColorInterval)
      }
    }, 500)
  }, [])
  return (
    <div className="rotating-n" style={props.extraCSS}>
      {/* <Illustration zoom={8}>
        <Shape stroke={20} color="white" rotate={{ x: Math.PI }} />
      </Illustration> */}
      {_rotating ? (
        <Illustration zoom={0}>
          <RotatingN
            letChangedColorAmount={letChangedColorAmount}
            rotating={_rotating}
            randomColor={randomColor}
          ></RotatingN>
        </Illustration>
      ) : (
        <div>abc</div>
      )}
    </div>
  )
}
function RotatingN(props) {
  const { colori } = props
  const ellipseRef = useRef()
  const nRef = useRef()
  // const [rotatingCounter, setRotatingCounter] = useState(initialState)
  let y = 0
  let z = 0
  // only once
  // console.log('props.rotating', props.rotating)
  // console.log('props.letChangedColorAmount', props.letChangedColorAmount)
  useEffect(() => {
    return () => {}
  })

  useRender(() => {
    if (nRef.current && props.rotating) {
      nRef.current.rotate.y = (y += 0.1) / TAU
      // nRef.current.rotate.z = (z += 0.1) / (TAU * 3)
    }
  }, [props.rotating])

  /* <Ellipse
      ref={ellipseRef}
      diameter={80}
      // rotate={{ x: -3 / 3 }}
      translate={{ x: 55, y: 80 }}
      stroke={20}
      color="white"
      // fill
    /> */
  return (
    <Shape
      ref={nRef}
      color={props.randomColor}
      translate={{ x: 24, y: 28, z: 0 }}
      closed={false}
      rotate={{ x: -0.6, y: 0 }}
      stroke={5}
      path={[
        { x: 0, y: 0 },
        { x: 0, y: -20 },
        { x: 14, y: 0 },
        { x: 14, y: -20 },
      ]}
    ></Shape>
  )
}
export default exportRotatingN
